<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">
                Content Management<br />
              </h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Australia's leading Plone CMS solutions provider.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content">
      <h1 class="is-size-4">
        We specialise in providing web CMS solutions to
        <span>government</span> and <span> private sector organisations </span>,
        primarily Plone CMS. Over the past few years, we have been responsible
        for <span>hundreds</span> of successful Plone implementations across a
        wide variety of industries and sectors. <br /><br />
        Why use <span>Plone</span>? <br /><br />
        Plone is a <span>mature</span>, <span>stable</span>,
        <span>secure</span>, <span>intuitive</span> and
        <span>flexible</span> open-source CMS ideal for use in an Internet,
        Intranet or Extranet environment. As a CMS, Plone is recognised as being
        a market leader and offers:
      </h1>
      <hr />
      <h1 class="is-size-4 columns">
        <div class="column is-half-tablet is-full-mobile">
          <li>
            Content management facilities through <span>any</span> modern web
            browser or mobile device.
          </li>
          <br />
          <li>
            Intuitive WYSIWYG editing facilities
            <span>do not require</span> users to have any HTML knowledge.
          </li>
          <br />
          <li>
            Workflow facilities to allow for <span>editorial reviews</span> and
            <span>approvals</span> prior to content publication.
          </li>
          <br />
          <li>
            Standard content types for
            <span>web pages, folders, news items, events</span> and
            <span>files</span>.
          </li>
        </div>
        <div class="column is-half-tablet is-full-mobile">
          <li>
            In-built search engine for <span>quick</span> and
            <span>advanced</span> searches.
          </li>
          <br />
          <li>
            <span>Robust</span> metadata support and <span>unique</span> search
            engine optimisation.
          </li>
          <br />
          <li>
            <span>Multilingual support</span> includes right to left textual
            display formats.
          </li>
          <br />
          <li>
            <span>Flexible</span> and <span>scalable</span> security modelling.
          </li>
          <br />
          To find out more about <span>Plone</span> you can visit plone.org
        </div>
      </h1>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/solutions-banner/content-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/solutions-banner/content-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Content-Management"
}
</script>
